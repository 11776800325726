<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col cols="12">
        <!-- Table Container Card -->
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Search -->
              <b-col cols="12" md="12" class="px-0">
                <div class="d-flex align-items-center justify-content-start">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="filterData.search"
                      class="d-inline-block mr-1 px-0 border-radius-right-0"
                      placeholder="بــــــــــحـــــــــث..."
                      @input="applyFilter"
                    />
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-table
            v-if="getAllBookings"
            :items="getAllBookings"
            responsive
            :fields="tableColumns"
            :per-page="filterData.per_page"
            primary-key="id"
            show-empty
            empty-text="لا يوجد أي بيانات"
            class="position-relative"
          >
            <!-- Column: phone_number -->
            <template #cell(phone_number)="data">
              <p class="m-0" dir="ltr">
                {{ data.value }}
              </p>
            </template>
            <!-- Column: id -->
            <template #cell(id)="data">
              <p class="m-0" dir="ltr">{{ data.value }}#</p>
            </template>
            <!-- Column: gender -->
            <template #cell(gender)="data">
              <p class="m-0" dir="ltr">
                {{ data.value ? data.value : "-----" }}
              </p>
            </template>

            <!-- Column: created_at -->
            <template #cell(created_at)="data">
              <p class="m-0" dir="ltr">
                {{ getFormatDate(data.value) }}
              </p>
            </template>

            <!-- Column: status -->
            <template #cell(status)="data">
              <b-badge pill :variant="findVariant(data.value)">
                {{ findStatus(data.value) }}
              </b-badge>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap" dir="ltr">
                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="25"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    :to="{ name: 'details-booking', params: { id: data.item.id } }"
                  >
                    <span class="align-middle ml-50">عرض التفاصيل</span>
                    <feather-icon size="20" icon="EyeIcon" />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <div v-if="showPagination && getAllBookings && getAllBookings.length > 0" class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <label>إدخالات</label>
                <v-select
                  v-model="filterData.per_page"
                  :dir="'rtl'"
                  :options="perPageOptions"
                  :clearable="false"
                  :searchable="false"
                  class="per-page-selector d-inline-block ml-50 mr-1"
                  style="width: 15%"
                  @input="handlePerPageChange($event)"
                />
                <span class="text-muted"
                  >عرض {{ 1 }} إلى {{ filterData.per_page }} من
                  {{ getTotalBookings.totalItems }} إدخالات</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="filterData.page"
                  :total-rows="getTotalBookings.totalItems"
                  :per-page="filterData.per_page"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="handlePageChange"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Arabic } from "flatpickr/dist/l10n/ar.js";

export default {
  props: {
    centerId: {
      type: String,
      default: "",
    },
    clientId: {
      type: String,
      default: "",
    },
    perPage: {
      type: Number,
      default: 10,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    flatPickr,
  },
  data() {
    return {
      filterData: {
        center_id: this.centerId,
        client_id: this.clientId,
        status: "",
        analyses: "",
        start_date: "",
        end_date: "",
        page: 1,
        per_page: this.perPage,
        order_by: "desc",
        search: "",
      },
      config: {
        mode: "range",
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "Y-m-d",
        altInput: true,
        ltr: true,
        dateFormat: "Y-m-d",
        // locale: Arabic, // locale for this instance only
        onChange: ([start, end]) => {
          if (start && end) {
            this.filterData.start_date = this.getFormatDate(start);
            this.filterData.end_date = this.getFormatDate(end);
          }
        },
      },
      perPageOptions: [10, 25, 50, 100],
      statusList: [
        {
          id: 1,
          title: "في انتظار الدفع",
        },
        {
          id: 2,
          title: "سحب العينات",
        },
        {
          id: 3,
          title: "الاشعة",
        },
        {
          id: 4,
          title: "الطبيب",
        },
        {
          id: 5,
          title: "إجراء التحاليل",
        },
        {
          id: 6,
          title: "مسؤول المختبر",
        },
        {
          id: 7,
          title: "مؤهل",
        },
        {
          id: 8,
          title: "غير مؤهل",
        },
      ],
      analysesList: [],
      tableColumns: [
        { key: "id", label: "رقم الحجز", sortable: false },
        { key: "name", label: "اسم العميل", sortable: false },
        { key: "phone", label: "رقم الموبايل", sortable: false },
        { key: "analyses_title", label: "التحليل", sortable: false },
        { key: "gender", label: "الجنس", sortable: false },
        { key: "created_at", label: "تاريخ الحجز", sortable: false },
        { key: "status", label: "الحالة", sortable: false },
        { key: "actions", label: " " },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllBookings: "getAllBookings",
      getTotalBookings: "getTotalBookings",
    }),
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      loadBookings: "loadBookings",
    }),
    showCardFilter() {
      const filter = document.getElementById("card-filter");
      filter.classList.toggle("show");
    },
    findStatus(data) {
      const object = this.statusList.find((item) =>
        item.id === data ? item.title : null
      );
      return object.title;
    },
    findVariant(data) {
      if (data === 1) {
        return "light-warning";
      } else if (data === 2) {
        return "light-info";
      } else if (data === 3) {
        return "light-info";
      } else if (data === 4) {
        return "light-info";
      } else if (data === 5) {
        return "light-info";
      } else if (data === 6) {
        return "light-info";
      } else if (data === 7) {
        return "light-success";
      } else if (data === 8) {
        return "light-danger";
      }
    },
    getFormatDate(date) {
      const d = new Date(date);
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      return `${ye}-${mo}-${da}`;
    },
    handlePageChange(value) {
      this.filterData.page = value;
      this.loadBookings(this.filterData);
    },
    handlePerPageChange(value) {
      this.loadBookings(this.filterData);
    },
    applyFilter() {
      this.loadBookings(this.filterData);
    },
  },
  mounted() {
    this.loadBookings(this.filterData);
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
