<template>
  <section>
    <b-row v-if="getClientDetails">
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="12"
                class="d-md-flex d-block justify-content-between align-items-end"
              >
                <div>
                  <h2 class="card-cus-title">
                    تفاصيل العميل
                  </h2>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <hr style="border-top: 2px solid #525254">
                <b-row class="w-100">
                  <b-col md="12">
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم العميل:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getClientDetails.id }}#
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col md="12">
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            حالة العميل:
                          </legend></b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            <b-badge
                              pill
                              :variant="findVariant(getClientDetails.status)"
                            >
                              {{ findStatus(getClientDetails.status) }}
                            </b-badge>
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col md="12">
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            اسم العميل:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getClientDetails.name }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col md="12">
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الهاتف:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 bg-inherit text-left"
                            dir="ltr"
                          >
                            {{ getClientDetails.phone }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>

                  <b-col
                    v-if="getClientDetails.nationality"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            الجنسية:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getClientDetails.nationality }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getClientDetails.email"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            البريد الالكتروني:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getClientDetails.email }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getClientDetails.birth_date"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            تاريخ الميلاد:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getClientDetails.birth_date }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getClientDetails.gender"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            الجنس:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getClientDetails.gender }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>

                  <b-col
                    v-if="getClientDetails.photo"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-1 bv-no-focus-ring col-form-label pt-0">
                            الصورة الشخصية:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <div class="image-after-upload">
                            <img
                              :src="
                                getClientDetails.photo.full_path +
                                  getClientDetails.photo.file_name
                              "
                              class="img-fluid"
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col md="12">
                    <b-row>
                      <b-col
                        v-if="getClientDetails.identification_type"
                        md="12"
                      >
                        <fieldset class="form-group">
                          <b-row class="w-100">
                            <b-col
                              md="2"
                              class="d-flex align-items-center"
                            >
                              <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                                نوع الهوية:
                              </legend>
                            </b-col>
                            <b-col md="8">
                              <p
                                class="form-control mb-0 d-flex align-items-center bg-inherit"
                              >
                                {{ getClientDetails.identification_type }}
                              </p>
                            </b-col>
                          </b-row>
                        </fieldset>
                      </b-col>
                      <b-col
                        v-if="getClientDetails.identification_number"
                        md="12"
                      >
                        <fieldset class="form-group">
                          <b-row class="w-100">
                            <b-col
                              md="2"
                              class="d-flex align-items-center"
                            >
                              <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                                رقم الهوية:
                              </legend>
                            </b-col>
                            <b-col md="8">
                              <p
                                class="form-control mb-0 d-flex align-items-center bg-inherit"
                              >
                                {{ getClientDetails.identification_number }}
                              </p>
                            </b-col>
                          </b-row>
                        </fieldset>
                      </b-col>
                      <b-col
                        v-if="getClientDetails.identification_photo"
                        md="12"
                      >
                        <fieldset class="form-group">
                          <b-row class="w-100">
                            <b-col
                              md="2"
                              class="d-flex align-items-center"
                            >
                              <legend class="mb-1 bv-no-focus-ring col-form-label pt-0">
                                صورة الهوية:
                              </legend>
                            </b-col>
                            <b-col md="8">
                              <div class="image-after-upload">
                                <img
                                  :src="
                                    getClientDetails.identification_photo.full_path +
                                      getClientDetails.identification_photo.file_name
                                  "
                                  class="img-fluid"
                                >
                              </div>
                            </b-col>
                          </b-row>
                        </fieldset>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="12"
                class=""
              >
                <!-- <hr style="border-top: 2px solid #525254" /> -->
                <h2 class="card-cus-title">
                  الحجوزات
                </h2>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <hr style="border-top: 2px solid #525254">
                <Bookings :client-id="getClientDetails.id" />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Bookings from '../../components/Booking.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormFile,
  BCardHeader,
  BCollapse,
  VBToggle,
  BFormTextarea,
  BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    FormWizard,
    TabContent,
    BFormFile,
    BCardHeader,
    BCollapse,
    VBToggle,
    BFormTextarea,
    BAlert,
    Bookings,
  },
  data() {
    return {
      statusList: [
        {
          id: 1,
          title: 'نشط',
        },
        {
          id: 2,
          title: 'موقف',
        },
      ],
      statusBookingsList: [
        {
          id: 1,
          title: 'في انتظار الدفع',
        },
        {
          id: 2,
          title: 'سحب العينات',
        },
        {
          id: 3,
          title: 'الاشعة',
        },
        {
          id: 4,
          title: 'الطبيب',
        },
        {
          id: 5,
          title: 'إجراء التحاليل',
        },
        {
          id: 6,
          title: 'مسؤول المختبر',
        },
        {
          id: 7,
          title: 'مؤهل',
        },
        {
          id: 8,
          title: 'غير مؤهل',
        },
      ],
      tableColumns: [
        { key: 'id', label: 'رقم الحجز', sortable: false },
        { key: 'name', label: 'اسم العميل', sortable: false },
        { key: 'phone', label: 'رقم الموبايل', sortable: false },
        { key: 'analyses_title', label: 'التحليل', sortable: false },
        { key: 'gender', label: 'الجنس', sortable: false },
        { key: 'created_at', label: 'تاريخ الحجز', sortable: false },
        { key: 'status', label: 'الحالة', sortable: false },
        { key: 'actions', label: ' ' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getClientDetails: 'getClientDetails',
    }),
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      loadClientDetails: 'loadClientDetails',
    }),
    getFormatDate(date) {
      const d = new Date(date)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
      const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
      return `${ye}-${mo}-${da}`
    },
    findStatus(data) {
      const object = this.statusList.find(item => (item.id === data ? item.title : null))
      return object.title
    },
    findVariant(data) {
      if (data === 2) {
        return 'light-warning'
      } if (data === 1) {
        return 'light-success'
      }
    },
    findBookingsStatus(data) {
      const object = this.statusBookingsList.find(item => (item.id === data ? item.title : null))
      return object.title
    },
    findBookingsVariant(data) {
      if (data === 1) {
        return 'light-warning'
      } if (data === 2) {
        return 'light-info'
      } if (data === 3) {
        return 'light-info'
      } if (data === 4) {
        return 'light-info'
      } if (data === 5) {
        return 'light-info'
      } if (data === 6) {
        return 'light-info'
      } if (data === 7) {
        return 'light-success'
      } if (data === 8) {
        return 'light-danger'
      }
    },
  },
  mounted() {
    this.loadClientDetails(this.$router.currentRoute.params.id)
  },
}
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
